<script setup>
import AuthBackground from "@/Components/Auth/AuthBackground.vue";
import AuthCard from "@/Components/Auth/AuthCard.vue";
import PrimaryButton from "@/Components/Buttons/PrimaryButton.vue";
import Checkbox from "@/Components/Checkbox.vue";
import InputError from "@/Components/InputError.vue";
import InputLabel from "@/Components/InputLabel.vue";
import TextInput from "@/Components/TextInput.vue";
import AuthLayout from "@/Layouts/AuthLayout.vue";
import { Link, useForm, usePage } from "@inertiajs/vue3";

defineProps({
    canResetPassword: Boolean,
    status: String,
    users: Object,
});

const form = useForm({
    email: "",
    password: "",
    remember: false,
});

const devForm = useForm({
    key: "",
});

const $page = usePage();

const submit = () => {
    form.transform((data) => ({
        ...data,
        remember: form.remember ? "on" : "",
    })).post(route("login"), {
        onFinish: () => form.reset("password"),
    });
};

const submitDevForm = () => {
    if (!$page.props.isLocal) {
        return false;
    }

    devForm.post("/laravel-login-link-login");
};
</script>

<template>
    <AuthLayout :title="__('Login')">
        <AuthBackground>
            <AuthCard>
                <div v-if="status" class="mb-4 font-medium text-sm text-green-600">
                    {{ status }}
                </div>

                <template v-if="$page.props.isLocal && Object.keys(users).length > 0">
                    <form @submit.prevent="submitDevForm" class="w-full border-b pb-4 mb-12">
                        <div>
                            <h2 class="mb-2 border-b pb-1 text-lg font-semibold">Developer login:</h2>
                            <InputLabel for="username" value="Gebruikersnaam" />
                            <select
                                v-model="devForm.key"
                                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                required
                            >
                                <option v-for="(user, id) in users" :value="id">
                                    {{ `${id} - ${user}` }}
                                </option>
                            </select>
                        </div>

                        <div class="mt-4 flex items-center justify-end">
                            <PrimaryButton
                                class="w-full py-2 text-xl bg-fuchsia-600"
                                :class="{ 'opacity-25': form.processing }"
                                :disabled="form.processing"
                            >
                                Inloggen als ontwikkelaar
                            </PrimaryButton>
                        </div>
                    </form>
                </template>

                <form class="w-full" @submit.prevent="submit">
                    <div>
                        <InputLabel for="email" :value="__('Email')" />
                        <TextInput
                            id="email"
                            v-model="form.email"
                            type="email"
                            class="mt-1 block w-full"
                            required
                            autofocus
                            autocomplete="username"
                        />
                        <InputError class="mt-2" :message="form.errors.email" />
                    </div>

                    <div class="mt-4">
                        <InputLabel for="password" :value="__('Password')" />
                        <TextInput
                            id="password"
                            v-model="form.password"
                            type="password"
                            class="mt-1 block w-full"
                            required
                            autocomplete="current-password"
                        />
                        <InputError class="mt-2" :message="form.errors.password" />
                    </div>

                    <div class="block mt-4">
                        <label class="flex items-center">
                            <Checkbox v-model:checked="form.remember" name="remember" />
                            <span class="ms-2 text-sm text-gray-600">{{ __("Remember me") }}</span>
                        </label>
                    </div>

                    <div class="flex flex-col justify-end mt-4 gap-4">
                        <PrimaryButton
                            class="w-full py-2 text-xl"
                            :class="{ 'opacity-25': form.processing }"
                            :disabled="form.processing"
                        >
                            {{ __("Log in") }}
                        </PrimaryButton>

                        <Link
                            v-if="canResetPassword"
                            :href="route('password.request')"
                            class="underline text-gray-600 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            {{ __("Forgot your password?") }}
                        </Link>
                    </div>
                </form>
            </AuthCard>
        </AuthBackground>
    </AuthLayout>
</template>
